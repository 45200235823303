import React, { useState, useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import Dashboard from './Dashboard';
import PrivateRoute from './PrivateRoute';
import Details from './Details';
import 'bootstrap/dist/css/bootstrap.min.css';
import Telangana from './Telangana';

function ErrorCard() {
  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="card text-center" style={{ width: '18rem' }}>
        <div className="card-body">
          <h5 className="card-title">Oops!</h5>
          <p className="card-text">We are working on it. Please check back later.</p>
        </div>
      </div>
    </div>
  );
}

function App() {
  const apiUrl = process.env.REACT_APP_API_URL;
  console.log("API URL:", apiUrl);
  const [auth, setAuth] = useState(false);

  // Load authentication status from localStorage
  useEffect(() => {
    const loggedIn = JSON.parse(localStorage.getItem('loggedIn'));
    setAuth(loggedIn || false);
  }, []);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/telangana" element={<Telangana />} />
        <Route path="/details/:id" element={<Details />} />
        <Route path="/login" element={<Login setAuth={setAuth} />} />
        <Route path="/dashboard" element={<PrivateRoute auth={auth} element={<Dashboard />} />} />



        {/* Catch-all route for any undefined paths */}
        <Route path="/working-on-it" element={<ErrorCard />} />
      </Routes>
    </div>
  );
}

export default App;
