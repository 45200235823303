import React, { useState } from 'react';
import { Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';

function Dashboard() {
    const [formData, setFormData] = useState({
        name: '',
        type: '',
        address: '',
        googleRating: '',
        inspectionDate: '',
        description: '',
        photos: '',
        tags: [],
        status: '',
        latitude: '',
        longitude: '',
    });

    const allTags = [
        'unhygienic',
        'expired food',
        'insects found',
        'rodents found',
        'dirty environment',
        'poor sanitation',
        'unpleasant odor',
        'food poisoning cases',
        'overcrowded',
        'slow service',
        'rude staff',
        'untrained staff',
        'incorrect billing',
        'overpriced',
        'noisy environment',
        'broken furniture',
        'poor lighting',
        'lack of hygiene protocols',
        'poor food quality',
        'stale food',
        'contaminated water',
        'improper waste disposal',
        'unsatisfactory temperature control',
        'other',
    ];

    const apiUrl = process.env.REACT_APP_API_URL;
    console.log("API URL:", apiUrl);
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const toggleTag = (tag) => {
        setFormData((prevState) => {
            const tags = prevState.tags.includes(tag)
                ? prevState.tags.filter((t) => t !== tag)
                : [...prevState.tags, tag];
            return { ...prevState, tags };
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            ...formData,
            photos: formData.photos.split('\n'), // Convert photos to an array
        };

        try {
            const response = await axios.post(`/api/add`, payload);
            console.log('Response:', response.data);
            alert('Data submitted successfully!');
        } catch (error) {
            console.error('Error:', error);
            alert('Error submitting data!');
        }
    };

    return (
        <div className="container mt-5">
            <Card>
                <Card.Header as="h5">Hotel Information</Card.Header>
                <Card.Body>
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formName">
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                type="text"
                                name="name"
                                placeholder="Name"
                                value={formData.name}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formType">
                            <Form.Label>Type</Form.Label>
                            <Form.Select
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                            >
                                <option value="">Select Type</option>
                                <option value="hotel">Hotel</option>
                                <option value="cafe">Cafe</option>
                                <option value="restaurant">Restaurant</option>
                                <option value="company">Company</option>
                                <option value="other">Other</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formAddress">
                            <Form.Label>Address</Form.Label>
                            <Form.Control
                                type="text"
                                name="address"
                                placeholder=""
                                value={formData.address}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formGoogleRating">
                            <Form.Label>Google Rating</Form.Label>
                            <Form.Control
                                type="number"
                                name="googleRating"
                                placeholder="3.5"
                                step="0.1"
                                min="1.0"
                                max="5.0"
                                value={formData.googleRating}
                                onChange={handleChange}
                            />
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formInspectionDate">
                            <Form.Label>Inspection Date</Form.Label>
                            <Form.Control
                                type="date"
                                name="inspectionDate"
                                value={formData.inspectionDate}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formDescription">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                rows={3}
                                placeholder="Why do you think about it ? Good / Bad or Any Suggestions"
                                value={formData.description}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPhotos">
                            <Form.Label>Photos (URLs, newline separated)</Form.Label>
                            <Form.Control
                                as="textarea"
                                name="photos"
                                rows={2}
                                placeholder={`https://pbs.twimg.com/media/GbCNSWBa8AA6LmR.jpg\nhttps://pbs.twimg.com/media/GbCNSWAbYAAKuzd.jpg`}
                                value={formData.photos}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formTags">
                            <Form.Label>Tags</Form.Label>
                            <div>
                                {allTags.map((tag) => (
                                    <span
                                        key={tag}
                                        className={`badge ${formData.tags.includes(tag) ? 'bg-primary' : 'bg-secondary'
                                            } mx-1`}
                                        style={{ cursor: 'pointer' }}
                                        onClick={() => toggleTag(tag)}
                                    >
                                        {tag.charAt(0).toUpperCase() + tag.slice(1)}
                                    </span>
                                ))}
                            </div>
                        </Form.Group>


                        <Form.Group className="mb-3" controlId="formStatus">
                            <Form.Label>Status</Form.Label>
                            <Form.Select
                                name="status"
                                value={formData.status}
                                onChange={handleChange}
                            >
                                <option value="">Select Status</option>
                                <option value="open">Open</option>
                                <option value="closed">Closed</option>
                                <option value="under renovation">Under Renovation</option>
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLat">
                            <Form.Label>Latitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="latitude"
                                placeholder="Enter Latitude"
                                value={formData.latitude}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLong">
                            <Form.Label>Longitude</Form.Label>
                            <Form.Control
                                type="text"
                                name="longitude"
                                placeholder="Enter Longitude"
                                value={formData.longitude}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit">
                            Submit
                        </Button>
                    </Form>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Dashboard;
