// Telangana.js

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { Carousel, Card, Container, Row, Col, Form } from 'react-bootstrap';
import "./App.css"
import 'bootstrap/dist/css/bootstrap.min.css';

const Telangana = () => {


    const apiUrl = process.env.REACT_APP_API_URL;
    console.log("API URL:", apiUrl);

    const [establishments, setEstablishments] = useState([]);
    const [search, setSearch] = useState('');

    useEffect(() => {
        const fetchEstablishments = async () => {
            try {
                const response = await axios.get(`/api/getall`);
                setEstablishments(response.data);
            } catch (error) {
                console.error("Error fetching establishments:", error);
            }
        };
        fetchEstablishments();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const weekday = date.toLocaleString('default', { weekday: 'long' });

        // Add ordinal suffixes (st, nd, rd, th) to the day
        const getOrdinalSuffix = (day) => {
            if (day > 3 && day < 21) return 'th'; // 4th-20th
            switch (day % 10) {
                case 1: return 'st';
                case 2: return 'nd';
                case 3: return 'rd';
                default: return 'th';
            }
        };

        return `${day}${getOrdinalSuffix(day)} ${month} ${year} (${weekday})`;
    };

    const filteredEstablishments = establishments
        .filter(establishment =>
            establishment.name.toLowerCase().includes(search.toLowerCase())
        )
        .sort((a, b) => new Date(b.inspectionDate) - new Date(a.inspectionDate));

    return (
        <Container className="my-3">

            <header className="mb-4">
                <Row className="align-items-center">
                    <Col xs={12} md={3} className="text-center mb-3 mb-md-0">
                        <img
                            src="https://upload.wikimedia.org/wikipedia/en/thumb/6/61/Emblem_of_Telangana.svg/640px-Emblem_of_Telangana.svg.png"
                            alt="Logo"
                            style={{ height: '100px' }}
                            className="img-fluid"
                        />
                    </Col>
                    <Col xs={12} md={9}>
                        <div className="text-center text-md-start">
                            <h1 className="h3">Commissioner of Food Safety, Telangana</h1>
                            <p className="text-muted mt-2">
                                Ensuring food safety standards and public health through strict enforcement of food regulations across Telangana.
                            </p>
                        </div>
                    </Col>
                </Row>
            </header>



            <Form.Control
                type="text"
                placeholder="Search..."
                className="mb-4"
                onChange={e => setSearch(e.target.value)}
            />

            <Row xs={1} md={2} lg={3} className="g-4">
                {filteredEstablishments.map(establishment => (
                    <Col key={establishment._id}>
                        <Card className="custom-card">
                            <Carousel>
                                {establishment.photos.map((photo, index) => (
                                    <Carousel.Item key={index}>
                                        <img className="d-block w-100" src={photo} alt={`Slide ${index + 1}`} />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            <Card.Body>
                                <Card.Title>{establishment.name}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">
                                    {formatDate(establishment.inspectionDate)}
                                </Card.Subtitle>
                                <Card.Text className="custom-description">
                                    {establishment.description}
                                </Card.Text>
                                <div className="tags">
                                    {establishment.tags.map(tag => (
                                        <span key={tag} className="badge bg-secondary me-1">{tag}</span>
                                    ))}
                                </div>
                                <Link to={`/details/${establishment._id}`} className="btn btn-primary mt-3">View Details</Link>
                            </Card.Body>
                        </Card>
                    </Col>

                ))}
            </Row>
        </Container>
    );
};

export default Telangana;
