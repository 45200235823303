import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element, auth, ...rest }) => {
    const isLoggedIn = JSON.parse(localStorage.getItem('loggedIn'));
    const userEmail = localStorage.getItem('userEmail');

    // Redirect to login if not authenticated
    return isLoggedIn && userEmail ? element : <Navigate to="/login" replace />;
};

export default PrivateRoute;
