import React, { useState } from 'react';

// Exporting the states array
export const states = [
    { name: 'Andhra Pradesh', capital: 'Amaravati', link: '/working-on-it', image: 'path/to/andhra-pradesh-map.png', active: false },
    { name: 'Arunachal Pradesh', capital: 'Itanagar', link: '/working-on-it', image: 'path/to/arunachal-pradesh-map.png', active: false },
    { name: 'Assam', capital: 'Dispur', link: '/working-on-it', image: 'path/to/assam-map.png', active: false },
    { name: 'Bihar', capital: 'Patna', link: '/working-on-it', image: 'path/to/bihar-map.png', active: false },
    { name: 'Chhattisgarh', capital: 'Raipur', link: '/working-on-it', image: 'path/to/chhattisgarh-map.png', active: false },
    { name: 'Goa', capital: 'Panaji', link: '/working-on-it', image: 'path/to/goa-map.png', active: false },
    { name: 'Gujarat', capital: 'Gandhinagar', link: '/working-on-it', image: 'path/to/gujarat-map.png', active: false },
    { name: 'Haryana', capital: 'Chandigarh', link: '/working-on-it', image: 'path/to/haryana-map.png', active: false },
    { name: 'Himachal Pradesh', capital: 'Shimla', link: '/working-on-it', image: 'path/to/himachal-pradesh-map.png', active: false },
    { name: 'Jharkhand', capital: 'Ranchi', link: '/working-on-it', image: 'path/to/jharkhand-map.png', active: false },
    { name: 'Karnataka', capital: 'Bengaluru', link: '/working-on-it', image: 'path/to/karnataka-map.png', active: false },
    { name: 'Kerala', capital: 'Thiruvananthapuram', link: '/working-on-it', image: 'path/to/kerala-map.png', active: false },
    { name: 'Madhya Pradesh', capital: 'Bhopal', link: '/working-on-it', image: 'path/to/madhya-pradesh-map.png', active: false },
    { name: 'Maharashtra', capital: 'Mumbai', link: '/working-on-it', image: 'path/to/maharashtra-map.png', active: false },
    { name: 'Manipur', capital: 'Imphal', link: '/working-on-it', image: 'path/to/manipur-map.png', active: false },
    { name: 'Meghalaya', capital: 'Shillong', link: '/working-on-it', image: 'path/to/meghalaya-map.png', active: false },
    { name: 'Mizoram', capital: 'Aizawl', link: '/working-on-it', image: 'path/to/mizoram-map.png', active: false },
    { name: 'Nagaland', capital: 'Kohima', link: '/working-on-it', image: 'path/to/nagaland-map.png', active: false },
    { name: 'Odisha', capital: 'Bhubaneswar', link: '/working-on-it', image: 'path/to/odisha-map.png', active: false },
    { name: 'Punjab', capital: 'Chandigarh', link: '/working-on-it', image: 'path/to/punjab-map.png', active: false },
    { name: 'Rajasthan', capital: 'Jaipur', link: '/working-on-it', image: 'path/to/rajasthan-map.png', active: false },
    { name: 'Sikkim', capital: 'Gangtok', link: '/working-on-it', image: 'path/to/sikkim-map.png', active: false },
    { name: 'Tamil Nadu', capital: 'Chennai', link: '/working-on-it', image: 'path/to/tamil-nadu-map.png', active: false },
    { name: 'Telangana', capital: 'Hyderabad', link: '/telangana', image: 'path/to/telangana-map.png', active: true },
    { name: 'Tripura', capital: 'Agartala', link: '/working-on-it', image: 'path/to/tripura-map.png', active: false },
    { name: 'Uttar Pradesh', capital: 'Lucknow', link: '/working-on-it', image: 'path/to/uttar-pradesh-map.png', active: false },
    { name: 'Uttarakhand', capital: 'Dehradun', link: '/working-on-it', image: 'path/to/uttarakhand-map.png', active: false },
    { name: 'West Bengal', capital: 'Kolkata', link: '/working-on-it', image: 'path/to/west-bengal-map.png', active: false },
];

function Home() {
    const [search, setSearch] = useState('');

    // Filter states based on search query
    const filteredStates = states
        .filter(state =>
            state.name.toLowerCase().includes(search.toLowerCase()) || // Search in state name
            state.capital.toLowerCase().includes(search.toLowerCase()) // Search in city name (capital)
        )
        .sort((a, b) => b.active - a.active); // Sort active states first


    return (
        <div className="container mt-4">
            {/* Search bar */}
            <h1 className="text-center mb-4">Food Safety India</h1>


            <div className="mb-4">
                <input
                    type="text"
                    className="form-control"
                    placeholder="Search for a state or city..."
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            {/* Display city first and state name */}
            <div className="row g-4">
                {filteredStates.map((state, index) => (
                    <div key={index} className="col-md-4">
                        <div
                            className={`card ${state.active ? 'bg-success text-white' : ''} h-100`}
                            style={{ borderRadius: '10px', cursor: 'pointer' }}
                            onClick={() => window.location.href = state.link} // Navigate on card click
                        >
                            <div className="card-body text-center">
                                <h5 className="card-title">{state.capital}</h5> {/* Show city (capital) first */}
                                <p className="card-text">State: {state.name}</p> {/* Show state name below */}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Home;