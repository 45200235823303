// Details.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import 'react-image-lightbox/style.css';
import Lightbox from 'react-image-lightbox';

const Details = () => {
    const apiUrl = process.env.REACT_APP_API_URL;
    console.log("API URL:", apiUrl);
    const { id } = useParams();
    const [establishment, setEstablishment] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [photoIndex, setPhotoIndex] = useState(0);

    useEffect(() => {
        const fetchEstablishment = async () => {
            try {
                const response = await axios.get(`/api/get/${id}`);
                setEstablishment(response.data);
            } catch (err) {
                setError('Establishment not found');
            } finally {
                setLoading(false);
            }
        };
        fetchEstablishment();
    }, [id]);

    if (loading) {
        return <p>Loading...</p>;
    }

    if (error) {
        return <p>{error}</p>;
    }

    const capitalizeFirstLetter = (str) => {
        if (!str) return '';
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    return (
        <div className="container my-5">
            <h1 className="text-center">{establishment.name}</h1>
            <div className="mb-4 text-center">
                <img
                    src={establishment.photos[0]}
                    alt="Main"
                    className="img-fluid rounded"
                    style={{ cursor: 'pointer', maxWidth: '100%' }}
                    onClick={() => { setIsOpen(true); setPhotoIndex(0); }}
                />
            </div>
            <div className="row">
                <div className="col-lg-6 col-md-12">
                    <p><strong>Type:</strong> {capitalizeFirstLetter(establishment.type)}</p>
                    <p><strong>Address:</strong> {establishment.address}</p>
                    <p><strong>Rating:</strong> {establishment.googleRating}</p>
                    <p><strong>Source:</strong> <a href={establishment.source} target="_blank" rel="noopener noreferrer">{establishment.source}</a></p>
                    <p><strong>Inspection Date:</strong> {new Date(establishment.inspectionDate).toLocaleDateString()}</p>
                </div>
                <div className="col-lg-6 col-md-12">
                    <p>{establishment.description}</p>
                    <div className="tags mb-3">
                        {establishment.tags.map(tag => (
                            <span key={tag} className="badge bg-secondary me-1">{tag}</span>
                        ))}
                    </div>
                </div>
            </div>
            <div className="mt-4">
                <h3>Photos</h3>
                <div className="row">
                    {establishment.photos.map((photo, index) => (
                        <div key={index} className="col-6 col-sm-4 col-md-3 col-lg-2 mb-3">
                            <img
                                src={photo}
                                alt={`Photo ${index + 1}`}
                                className="img-fluid img-thumbnail"
                                style={{ cursor: 'pointer' }}
                                onClick={() => { setIsOpen(true); setPhotoIndex(index); }}
                            />
                        </div>
                    ))}
                </div>
            </div>

            {isOpen && (
                <Lightbox
                    mainSrc={establishment.photos[photoIndex]}
                    nextSrc={establishment.photos[(photoIndex + 1) % establishment.photos.length]}
                    prevSrc={establishment.photos[(photoIndex + establishment.photos.length - 1) % establishment.photos.length]}
                    onCloseRequest={() => setIsOpen(false)}
                    onMovePrevRequest={() =>
                        setPhotoIndex((photoIndex + establishment.photos.length - 1) % establishment.photos.length)
                    }
                    onMoveNextRequest={() =>
                        setPhotoIndex((photoIndex + 1) % establishment.photos.length)
                    }
                />
            )}
        </div>
    );
};

export default Details;
